import { faEdit, faMapMarked } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios";
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom/dist";
import useMapDialog from "../Map/useMapDialog";
import { useTranslation } from "react-i18next";
import useLoading from "../Loading/useLoading";
import Loading from "../Loading/Loading";
import Map from "../Map/Map";
import f from '../Installation/InstallationFunctions';
import { FloatingMessage, useFloatingMessage } from "../UserAction/FloatingMessage";

const Inspections = () => {
    const [installation, setInstallation] = useState('');
    const [inspections, setInspections] = useState([]);
    const [files, setFiles] = useState([]);
    const location = useLocation();
    const loading = useLoading();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const floatMsg = useFloatingMessage();
    
    const handleMapHide = () => {
        loading.showLoading(t('loading-get-data'));

        getData();
    };

    const mapDialog = useMapDialog(handleMapHide);

    const getData = async () => {
        const installationId = location.state.id;
        const installation = (await axios.post('/api/getInstallationFromId', { installationId: installationId })).data;
        const inspections = (await axios.get('/api/inspectionsByInstallationId', { params: { installationId: installationId } })).data.sort((a, b) => new Date(b.inspectedAt) - new Date(a.inspectedAt));
        const files = (await axios.get('/api/getFilesFromInstallationId', { params: { installationId: installationId } })).data;

        setInstallation(installation);
        setInspections(inspections);
        setFiles(files);

        loading.hideLoading();
    };

    const openFileInNewTab = async (fileUrl, fileExtension) => {
        try {
            const response = await fetch(fileUrl);
            if (!response.ok) floatMsg.show(t('file-not-found'), true);

            let mimeType;
            switch (fileExtension.toLowerCase()) {
                case 'pdf':
                    mimeType = 'application/pdf';
                    break;
                case 'png':
                    mimeType = 'image/png';
                    break;
                case 'jpg':
                case 'jpeg':
                    mimeType = 'image/jpeg';
                    break;

                default:
                    const blob = await response.blob();
                    const downloadUrl = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = downloadUrl;
                    a.download = fileUrl.split('/').pop(); // Extracts file name from URL
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(downloadUrl);
                    a.remove();
                    return;
            }

            // For PDFs and images, open in a new tab
            const blob = new Blob([await response.blob()], { type: mimeType });
            const blobUrl = URL.createObjectURL(blob);
            const newTab = window.open(blobUrl, '_blank');

        } catch (error) {
            await axios.post('/api/addError', { message: 'Error when opening inspection file: ' + error }, {});
            console.error('Error opening file', error);
        }
    };

    useEffect(() => {
        loading.showLoading(t('loading-get-data'));
        getData();
    }, [])

    return (
        <div className="inspectionsContainer">
            <Loading isLoading={loading.isLoading} message={loading.message} />
            <FloatingMessage {...floatMsg} />
            <Map {...mapDialog} />
            <div className='installationIDContainer'>
                <div className='installation-info-editIcon'>
                    <FontAwesomeIcon icon={faEdit} size='lg' className={'display-none'} />
                </div>
                <div className='installation-info-container'>
                    <div className='installation-name'>ID.: {installation.installationId} - {installation.name}</div>
                    <div className='installation-address'>{installation.address}</div>
                </div>
                <div className='installation-info-mapIcon' onClick={() => mapDialog.show({ lat: installation.fullAddress.lat, lng: installation.fullAddress.lng }, true, installation.installationId)}>
                    <FontAwesomeIcon icon={faMapMarked} size='lg' />
                </div>
            </div>
            <div className="inspectionsContent">
                <div className="inspectionsHeading">{t('inspections-heading')}</div>
                <div className="inspectionsTable">
                    {inspections && inspections.map(inspection => {
                        const file = files && files.find(file => file.fileId === inspection.fileId);
                        return (
                            <div className="inspectionRow" onClick={() => {
                                const fileExtension = file.name.split('.').pop().toLowerCase();
                                if (["png", "jpg", "jpeg", "pdf"].includes(fileExtension)) {
                                    console.log("opening file in new tab");
                                    openFileInNewTab(file.url, fileExtension);
                                }
                            }}>
                                <InspectionSign inspection={inspection} loading={loading} key={inspection.inspectionId}/>
                                <div className="inspectionDate">{file.name}</div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="btnGroup">
                <button className='back-button' onClick={() => navigate(`/installation/${installation.installationId}`)}>{t('back-button')}</button>
            </div>
        </div>
    )
}

const InspectionSign = ({ inspection, loading }) => {
    const [inspectionSignUrl, setInspectionSignUrl] = useState('');
    const { t } = useTranslation();

    const getinspectionSignUrl = async () => {
        if (inspection) {
            const inspectionSignUrl = (await f.createControlSticker(new Date(inspection.inspectedAt), inspection.isApproved));
            setInspectionSignUrl(inspectionSignUrl);
        }

        loading.hideLoading();
    };

    useEffect(() => {
        loading.showLoading(t('loading-get-data'));
        getinspectionSignUrl();
    }, [])

    return (
        <div className='inspectionsInspectionSignContainer'>
            {inspectionSignUrl && <img className="inspectionSignMini" alt='Kontrollmerke' src={inspectionSignUrl}></img>}
        </div>
    );
}

export { Inspections, InspectionSign };