import React, { useEffect } from 'react';
import axios from 'axios';  // Assuming you're using axios to make HTTP requests
import useLoading from '../Loading/useLoading';
import Loading from "../Loading/Loading";
import { useTranslation } from "react-i18next";
import { useTokenNavigate } from '../NavigateHook';
import { redirect } from 'react-router-dom';

const VerifyUserToken = (props) => {
    const loading = useLoading();
    const {t} = useTranslation();
    const nav = useTokenNavigate();

    useEffect(() => {
        loading.showLoading(t("verifying-token"));
        // Extract JWT token from the URL
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');

        (async()=>{
            let redirectTo = "/projects";
            if (token) {
                // Send the token to backend for verification
                const response = await axios.post('/api/verifyRegisterToken', { token: token })

                if(response.status === 200){
                    loading.showLoading(t("token-verified"));
                    redirectTo = response.data.redirectTo;
                }
                else {
                    await axios.post('/api/addError', { message: 'Error when verifying token: ' + response.json() }, {});
                    loading.showLoading(t("token-verification-failed"));
                }

            } else {
                loading.showLoading(t("token-verification-failed"));
            }
            console.log("Redirecting to",redirectTo);
            setTimeout(()=>nav.navigateTo("/Login",{redirectTo}),2500);
        })();
        
    }, []);  // The empty array ensures this effect runs once when the component mounts

    return (
        <div>
        <Loading isLoading={loading.isLoading} message={loading.message} />
        </div>
    );
};

export default VerifyUserToken;
