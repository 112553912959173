import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Loading from '../Loading/Loading';
import useLoading from '../Loading/useLoading';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom/dist';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { Wizard } from '../Wizard/Wizard';
import { useFloatingMessage, FloatingMessage } from '../UserAction/FloatingMessage';
import f from '../Installation/InstallationFunctions';
import { UserInputDialog, useUserInputDialog } from '../UserAction/UserInputDialog';

function InstallationApproval() {
    const { t } = useTranslation();
    const loading = useLoading();
    const navigate = useNavigate();
    const location = useLocation();
    const [id, setId] = useState('');
    const [installation, setInstallation] = useState(null);
    const [bomLines, setBomLines] = useState([]);
    const [user, setUser] = useState(null);
    const [showInspectionWizard, setShowInspectionWizard] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isBOMPresent, setBOMPresent] = useState(false);
    const [isControlPerformed, setControlPerformed] = useState(false);
    const [redirectTo, setRedirectTo] = useState('');
    const floatMsg = useFloatingMessage();
    const [roofType, setType] = useState("");
    const FSMImage = "https://images-eu-prod.cms.commerce.dynamics.com/cms/api/xgkdgtpxf/imageFileData/MD2yH1?pubver=1";
    const FSGImage = "https://images-eu-prod.cms.commerce.dynamics.com/cms/api/xgkdgtpxf/imageFileData/MD2yGY?pubver=1";
    const FSSImage = "https://images-eu-prod.cms.commerce.dynamics.com/cms/api/xgkdgtpxf/imageFileData/MD2yH4?pubver=1";
    const FSFImage = "https://images-eu-prod.cms.commerce.dynamics.com/cms/api/xgkdgtpxf/imageFileData/MD2GuK?pubver=1";
    const controlDialog = useUserInputDialog();

    const showMessage = (message, isError) => {
        floatMsg.show(message, isError);
        // Set a timer to hide the message after 10 seconds
        const timer = setTimeout(() => {
            floatMsg.hide();
        }, 10000);

        // Clear the timer when the component unmounts or if the message changes
        return () => clearTimeout(timer);
    };

    const getRedirectUrl = async () => {
        const redirectURL = location.state && location.state.redirectTo;
        console.log("Got the following redirect url", redirectURL);

        if (redirectURL) {
            setRedirectTo(redirectURL);
        }
    };

    const getInstallationId = async () => {
        const installationId = (await axios.get('/api/getCurrentInstallationId')).data;
        setId(installationId);
        return installationId;
    };

    const getInstallationImage = () => {
        let image = '';
        const type = installation.installationId.substring(0, 3);

        switch (type) {
            case "FSM":
                image = FSMImage;
                break;
            case "FSG":
                image = FSGImage;
                break;
            case "FSS":
                image = FSSImage;
                break;
            case "FSF":
                image = FSFImage;
                break;
            default:
                image = '';
        }

        return image;
    };

    const getInstallationType = () => {
        const type = installation.installationId.substring(0, 3);

        switch (type) {
            case "FSM":
                return t('FSM');
            case "FSG":
                return t('FSG');
            case "FSS":
                return t('FSS');
            case "FSF":
                return t('FSF');
            default:
                return "";
        }
    }

    const getInstallationData = async (installationId) => {
        const installation = (await axios.post('/api/getInstallationFromId', { installationId: installationId })).data;
        const files = (await axios.get('/api/getFilesFromInstallationId', { params: { installationId: installationId } })).data;
        const user = (await axios.get('/api/getCurrentUser')).data;
        const bomLines = (await axios.get(`/api/bom/byInstallationId/${installationId}`)).data;
        const type = installationId.substring(0, 3);

        console.log(files);

        setInstallation(installation);
        setUser(user);

        files.map(file => {
            if (file.type === 5) {
                setControlPerformed(true);
            }
        });

        if(bomLines && bomLines.length > 0) {
            setBomLines(bomLines);
            setBOMPresent(true);
        }

        setType(type);

        loading.hideLoading();
    };

    const handleControlRegistration = () => {
        controlDialog.show(() => { setShowInspectionWizard(true) }, t(`register-control`), t('control-message'), [], [], [], [], t('next-button'), getInstallationImage(), [], t(`register-control-${roofType}`));
    };

    const inspectionComplete = async (data, res) => {
        console.log(data, "Result", res);

        loading.showLoading(t('submitting-control-report'));

        try {
            const date = new Date();
            const dateString = date.toLocaleDateString(undefined, { year: '2-digit', month: '2-digit', day: '2-digit' }).replace(/\//g, '.');
            const timeString = date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' }).replace(/:/g, '.');
            const readableDate = dateString + ' ' + timeString;

            const inspectionData = {
                InspectionTemplateUrl: "https://files-eu-prod.cms.commerce.dynamics.com/cms/api/xgkdgtpxf/binary/MD2w5G",
                InspectionUser: user,
                InstallationLogoUrl: getInstallationImage(),
                Installation: installation,
                InspectionData: data,
                InspectionDocumentName: `${t('installation-document-name')} ${readableDate}.pdf`,
                InspectionType: 5,
                RoofType: getInstallationType(),
                RoofTypeLogoUrl: getInstallationImage(),
                BomLines: bomLines
            }
            const pdfFile = await axios.post('/api/createInspectionDocument', { inspectionData: inspectionData }, {
                maxContentLength: Infinity,
                maxBodyLength: Infinity,
            });

            try {

                console.log(res, pdfFile.data);
                const response = await axios.post('/api/createInspection', { isApproved: res, fileId: pdfFile.data.fileId }, {});

                if (response.status !== 200) {
                    loading.hideLoading();
                    showMessage(t('control-report-submit-error'), true);
                    await axios.post('/api/addError', { message: 'Error when creating inpsection: ' + response.json() }, {});
                }


            } catch (error) {
                showMessage(t('control-report-submit-error'), true);
                await axios.post('/api/addError', { message: 'Error when creating inpsection: ' + error }, {});
            }

            loading.hideLoading();
            showMessage(t('control-report-submitted'), false);
            getInstallationData(installation.installationId);
        } catch (error) {
            console.log(error);
            await axios.post('/api/addError', { message: 'Error when registering inpsection: ' + error }, {});
            loading.hideLoading();
        }

        setShowInspectionWizard(false);
    };

    useEffect(() => {
        loading.showLoading(t("loading"));
        (async () => {
            await getRedirectUrl();
            const installationId = await getInstallationId();
            await getInstallationData(installationId);
        })();
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        loading.showLoading(t(''));

        if (isBOMPresent && isControlPerformed) {
            try {
                const response = await (axios.post('/api/uploadFDV', { installationId: id }));

                if (response.status === 200)
                    navigate(`/installation/${id}`);
                else {
                    await axios.post('/api/addError', { message: 'Error when uploading FDV: ' + response.json() }, {});
                    showMessage(t('finish-installation-registration-error'), true);
                }

            } catch (error) {
                await axios.post('/api/addError', { message: 'Error when uploading FDV: ' + error }, {});
                showMessage(t('finish-installation-registration-error'), true);
            }
        }
        else {
            navigate(`/installation/${id}`);
        }

        loading.hideLoading();
    };

    const controlIconClass = isControlPerformed ? "" : "iconHidden";
    const bomIconClass = isBOMPresent ? "" : "iconHidden";

    return (
        <div className="approveInstallationContainer">
            <UserInputDialog {...controlDialog}/>
            <FloatingMessage {...floatMsg} />
            {showInspectionWizard && <Wizard onCompleted={inspectionComplete} onCancelled={() => setShowInspectionWizard(false)} flowFetchFunction={async () => await axios.get('/api/getInstallationFinishedFlow')} />}
            <div className='approveInstallationInstallationIdContainer'>
                <div className='approveInstallationInstallationId'>ID.: {id}</div>
            </div>
            <Loading isLoading={loading.isLoading} message={loading.message} />
            {id && <form onSubmit={handleSubmit} className='approveInstallationForm'>
                {errorMessage && <p className="errorMessage">{errorMessage}</p>}
                <p className='approve-installation-text'>{t('approve-installation')}</p>
                <div className="approve-installation-input">
                    <div className="div approve-steps">{t("approve-installation-bom")}</div>
                    <div className='bomGroup'>
                        <button className='approve-button' type='button' disabled={isBOMPresent && isControlPerformed} onClick={() => navigate('/createBom', { state: { redirectTo: '/approveInstallation' } })}>{t('create-bom-button')}</button>
                        <div className='approve-checkbox'><FontAwesomeIcon icon={faCheck} className={bomIconClass} size='lg' /></div>
                    </div>
                </div>
                <div className="approve-installation-input">
                    <div className="div approve-steps">{t("approve-installation-control")}</div>
                    <div className='controlGroup'>
                        <button className='approve-button' type='button' disabled={!isBOMPresent || (isBOMPresent && isControlPerformed)} onClick={() => { handleControlRegistration() }}>{t('perform-control')}</button>
                        <div className='approve-checkbox'><FontAwesomeIcon icon={faCheck} className={controlIconClass} size='lg' /></div>
                    </div>
                </div>
                <div className='formButtonGroup'>
                    {redirectTo && <button className='backButton' type="button" onClick={() => navigate(redirectTo)}><FontAwesomeIcon icon={faAngleLeft} size='lg' className="iconLeft" />{t('back-button')}</button>}
                    <button className='saveButton' type="submit">{isBOMPresent && isControlPerformed ? t('save-button') : t('temp-save-button')}</button>
                </div>
            </form>}
        </div>
    );
}

export default InstallationApproval;