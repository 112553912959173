import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faBarsStaggered, faEdit, faList } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import defaultImage from '../../image.svg';
import axios from 'axios';
import useLoading from '../Loading/useLoading';
import Loading from "../Loading/Loading";
import { useNavigate } from "react-router-dom";

const ProfileForm = () => {
    const { t } = useTranslation();
    const [previewUrl, setPreviewUrl] = useState(defaultImage);
    const [logoClass, setLogoClass] = useState("profileUserCompanyLogoContainer");
    const loading = useLoading();
    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    const getUser = async () => {

        try {
            const response = await axios.get('/api/getCurrentUser');

            return response.data;
        } catch (error) {
            await axios.post('/api/addError', { message: 'Error when retreiving user: ' + error }, {});
            return null;
        }
    };

    useEffect(() => {
        loading.showLoading();
        (async () => {
            const fetchedUser = await getUser();

            if (fetchedUser) {
                if (fetchedUser.companyLogoUrl) {
                    setLogoClass("profileUserCompanyLogoContainer neutral");
                    setPreviewUrl(fetchedUser.companyLogoUrl);
                }

                setUser(fetchedUser);
            }
            loading.hideLoading();

        })();
    }, []);

    return (
        <div className="profileFormContainer">
            <Loading isLoading={loading.isLoading} message={loading.message} />
            <div className="profileHeaderContainer">
                <div className="profileHeader">{t('profile-heading')}</div>
                <div className="profileEdit">
                    <button className="profileEditBtn" onClick={() => navigate("/editProfile")}>{t('profile-edit')}<FontAwesomeIcon icon={faEdit} size="lg" className="iconRight" /></button>
                </div>
            </div>
            <div className="profileContentContainer">
                <div className="profileUserInfoContainer">
                    <div className={logoClass}>
                        <img src={previewUrl} className="profileUserCompanyLogo" alt="" />
                    </div>
                    <div className="profileUserName">{user && user.firstName} {user && user.lastName}</div>
                    <div className="profileUserCompany">{user && user.company}</div>
                    <div className="profileUserRoleContainer">
                        <div className="profileUserRoleLabel">{t('profile-user-role')}</div>
                        <div className="profileUserRole">{ }</div>
                    </div>
                    <div className="profileUserContactInfo">
                        <div className="profileUserPhoneContainer">
                            <div className="profileUserPhoneLabel">{t('enter-phone-number')}</div>
                            <div className="profileUserPhone">{user && user.telephoneNumber}</div>
                        </div>
                        <div className="profileUserEmailContainer">
                            <div className="profileUserEmailLabel">{t('enter-email')}</div>
                            <div className="profileUserEmail">{user && user.email}</div>
                        </div>
                    </div>
                </div>
                <div className="profileLinksContainer">
                    <div onClick={() => navigate("/projects")} className="profileLink">
                        <div className="profileLinkIconContainer">
                            <FontAwesomeIcon icon={faList} size="lg" className="profileLinkIcon" />
                        </div>
                        <div className="profileLinkLabel">{t('profile-projects')}</div>
                    </div>
                    <div onClick={() => navigate("/installations")} className="profileLink">
                        <div className="profileLinkIconContainer">
                            <FontAwesomeIcon icon={faList} size="lg" className="profileLinkIcon" />
                        </div>
                        <div className="profileLinkLabel">{t('profile-installations')}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProfileForm;