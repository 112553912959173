import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useTokenNavigate, useUrlData } from '../NavigateHook'; // Adjust the path as needed
import axios from 'axios';
import Loading from '../Loading/Loading';
import useLoading from '../Loading/useLoading';
import { useTranslation } from "react-i18next";
import { AutoSuggestComponent } from '../AutoSuggestHook';
import { DateTime } from 'luxon';
import { GoogleMap, LoadScript, LoadScriptNext, Marker } from '@react-google-maps/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTrashCan, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom/dist';
import { FloatingMessage, useFloatingMessage } from '../UserAction/FloatingMessage';

function InstallationInput() {
    ;
    const { t } = useTranslation();
    const loading = useLoading();
    const [id, setId] = useState(null);
    const [key, setKey] = useState(null);
    const [installationExists, setInstallationExists] = useState(false);
    const [countries, setCountries] = useState([]);
    const nav = useTokenNavigate();
    const navigate = useNavigate();
    const [currentCountry, setCurrentCountry] = useState(null);
    const dataFetcher = useUrlData();
    const [errorMessage, setErrorMessage] = useState(null);
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [comments, setComments] = useState('');
    const [position, setPosition] = useState({ lat: 0, lng: 0 });

    const [createdDateTime, setCreatedDateTime] = useState('');

    const floatMsg = useFloatingMessage();

    const showMessage = (message, isError) => {
        floatMsg.show(message, isError);
        // Set a timer to hide the message after 10 seconds
        const timer = setTimeout(() => {
            floatMsg.hide();
        }, 10000);

        // Clear the timer when the component unmounts or if the message changes
        return () => clearTimeout(timer);
    };

    const getCountries = async () => {
        return (await axios.get('https://restcountries.com/v3.1/all')).data;
    }

    const getAddressFromLatLng = async (lat, lng) => {
        console.log(lat, lng);
        //getReverseGeocodingData(lat, lng);
        const apiUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`;

        try {
            const response = await axios.get(apiUrl);
            //console.log(countries);
            const country = countries.find(c => c.cca2.toLowerCase() === response.data.address?.country_code);
            //console.log(country);
            setCurrentCountry(country);
            const data = response.data;
            //console.log(data);
            //const fullAddress = `${data.address.road || ''} ${data.address.house_number || ''}, ${data.address.postcode || ''} ${data.address.city || data.address.village || data.address.town || ''}, ${data.address.country || ''}`/*{
            return {
                address: {
                    street: data.address?.road || '',
                    houseNumber: data.address?.house_number || '',
                    zipCode: data.address?.postcode || '',
                    city: data.address?.city || data.address?.village || data.address?.town || '',
                    countryCode: data.address?.country_code.toUpperCase() || '',
                    lat: parseFloat(data.lat),
                    lng: parseFloat(data.lon)
                }
            };
            //return fullAddress;



        } catch (error) {
            await axios.post('/api/addError', { message: 'Error when fetching address data: ' + error }, {});
            console.error("There was a problem fetching or processing the data:", error);
            return null;  // Or handle the error in some other way
        }
    }

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(async (position) => {
            const initialPosition = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            };
            const address = await getAddressFromLatLng(initialPosition.lat, initialPosition.lng);
            setPosition({ lat: address.address.lat, lng: address.address.lng });
            setAddress(`${address.address.street || ''} ${address.address.houseNumber || ''}, ${address.address.zipCode || ''} ${address.address.city || address.address.village || address.address.town || ''}, ${address.address.countryCode || ''}`)
        });
    }, []);

    useEffect(() => {
        loading.showLoading(t("verifying-installation-id"));
        (async () => {
            const { id, tkn } = await dataFetcher.getUrlData();
            const installation = (await axios.post('/api/getInstallationFromId', { installationId: id })).data;
            const keyVerfified = (await axios.post('/api/verifyInstallationKey', { installationId: id, key: tkn })).data;
            if (id && tkn && keyVerfified && installation == null) {
                setId(id);
                setKey(tkn);
                loading.hideLoading();

                setCountries(await getCountries());
            }
            else if (keyVerfified && installation != null) {
                setId(id);
                setKey(tkn);
                setInstallationExists(true);
                setName(installation.name)
                setAddress(installation.address)
                installation.fullAddress.lat !== 0 && installation.fullAddress.lng !== 0 && setPosition({ lat: installation.fullAddress.lat, lng: installation.fullAddress.lng });
                setComments(installation.comments);
                setCreatedDateTime(installation.createdDateTime);
                loading.hideLoading();
            }
            else {
                //Reaching this point indicates that the installation already exists, or that the key is invalid
                //either way well notify the user and redirect them to the login page
                loading.showLoading(t("invalid-installation-id"));
                setTimeout(() => nav.navigateTo('/login', { redirectTo: `/installation/${id}` }), 2000);
            }
        })();
    }, [])

    const handleSubmit = (e) => {
        loading.showLoading(t('submitting-installation-data-message'));

        const formData = {
            name: name,
            address: address,
            fullAddress: {
                lat: position.lat,
                lng: position.lng,
                addressId: 0,
                street: '',
                houseNumber: '',
                zipCode: '',
                city: '',
                countryCode: '',
            },
            comments: comments
        };

        if (!installationExists) {
            (async () => {
                //TODO: Verify submitted data and create installation
                e.preventDefault();
                console.log(formData, id, key);
                setTimeout(() => loading.hideLoading(), 3000);

                const response = await axios.post('/api/createInstallation', { installationId: id, key: key, ...formData });

                if (response.status === 200) {
                    nav.navigateTo(`/installation/${id}`);
                } else {
                    await axios.post('/api/addError', { message: 'Error when creating installation: ' + response.json() }, {});
                }
            })();
        } else {
            (async () => {
                //TODO: Verify submitted data and create installation
                e.preventDefault();
                console.log(formData, id, key);
                setTimeout(() => loading.hideLoading(), 3000);

                const response = await axios.post('/api/updateInstallation', { installationId: id, createdDateTime: createdDateTime, ...formData });

                if (response.status === 200) {
                    nav.navigateTo(`/installation/${id}`);
                } else {
                    await axios.post('/api/addError', { message: 'Error when updating installation: ' + response.json() }, {});
                }
            })();
        }

    };

    const handleNext = (e) => {
        loading.showLoading(t('submitting-installation-data-message'));

        const formData = {
            name: name,
            address: address,
            fullAddress: {
                lat: position.lat,
                lng: position.lng,
                addressId: 0,
                street: '',
                houseNumber: '',
                zipCode: '',
                city: '',
                countryCode: '',
            },
            comments: comments
        };

        if (!installationExists) {//todo: set installation exists
            (async () => {
                //TODO: Verify submitted data and create installation
                e.preventDefault();
                console.log(formData, id, key);
                setTimeout(() => loading.hideLoading(), 3000);

                const response = await axios.post('/api/createInstallation', { installationId: id, key: key, ...formData });

                if (response.status === 200) {
                    navigate('/approveInstallation', { state: { redirectTo: `/createInstallation/?id=${id}&tkn=${key}` } });
                } else {
                    await axios.post('/api/addError', { message: 'Error when creating installation: ' + response.json() }, {});
                }
            })();
        } else {
            (async () => {
                //TODO: Verify submitted data and create installation
                e.preventDefault();
                console.log(formData, id, key);
                setTimeout(() => loading.hideLoading(), 3000);

                const response = await axios.post('/api/updateInstallation', { installationId: id, createdDateTime: createdDateTime, ...formData });

                if (response.status === 200) {
                    navigate('/approveInstallation', { state: { redirectTo: `/createInstallation/?id=${id}&tkn=${key}` } });
                } else {
                    await axios.post('/api/addError', { message: 'Error when updating installation: ' + response.json() }, {});
                    showMessage(t('installation-update-failed'), true);
                }
            })();
        }
    }

    const getCountryName = (country) => {
        try {
            return country.name.nativeName[Object.keys(country.languages)[0]].common;
        }
        catch (e) {
            return country.name && country.name.common ? country.name.common : " - ";
        }
    }

    const handleMarkerDragEnd = useCallback(async (event) => {
        const newPos = { lat: event.latLng.lat(), lng: event.latLng.lng() };
        const address = await getAddressFromLatLng(newPos.lat, newPos.lng);
        setAddress(`${address.address.street || ''} ${address.address.houseNumber || ''}, ${address.address.zipCode || ''} ${address.address.city || address.address.village || address.address.town || ''}, ${currentCountry || address.address.countryCode || ''}`)
        setPosition(newPos);
    }, []);

    const MapWithMarker = useMemo(() => (
        <GoogleMap
            center={position}
            zoom={17}
            mapContainerStyle={{ width: '350px', height: '150px' }}
            options={{
                mapTypeId: 'satellite',
                zoomControl: true,
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: false,
                contolSize: 5,
                keyboardShortcuts: false
            }}
        >
            <Marker
                position={position}
                draggable={true}
                onDragEnd={handleMarkerDragEnd}
            />
        </GoogleMap>
    ), [position, handleMarkerDragEnd]);

    return (
        <div className="createInstallationContainer">
            <div className='createInstallationInstallationIdContainer'>
                <div className='createInstallationInstallationId'>ID.: {id}</div>
            </div>
            <FloatingMessage {...floatMsg} />
            <Loading isLoading={loading.isLoading} message={loading.message} />
            <div className='registerInstallationFormContainer'>
                {(id && key) && <form onSubmit={handleSubmit} className='registerInstallationForm'>
                    {errorMessage && <p className="errorMessage">{errorMessage}</p>}
                    <p className='register-installation-title'>{t('register-new-installation')}</p>
                    <LoadScriptNext googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
                        {MapWithMarker}
                    </LoadScriptNext>
                    <div className='inputContainer'>
                        <div className="input-default input-name">
                            <div className="div">{t("installation-name")}</div>
                            <input
                                className='input-style'
                                type="text"
                                name="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Mitt bygg"
                                onFocus={e => e.target.select()}

                            />
                        </div>
                        <div className="input-default">
                            <div className="div">{t("installation-address")}</div>
                            <input
                                className='input-style'
                                type="text"
                                name="address"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                placeholder="Min adresse"
                                onFocus={e => e.target.select()}

                            />
                        </div>
                        <div className="input-default input-comments">
                            <div className="div">{t("installation-comments")}</div>
                            <textarea
                                className='input-style'
                                type="text"
                                name="comments"
                                value={comments}
                                onChange={(e) => setComments(e.target.value)}
                                placeholder="Skriv inn notater her..."
                                onFocus={e => e.target.select()}

                            />
                        </div>
                    </div>
                    <div className='formButtonGroup'>
                        <button className='tempSaveButton' type="submit">{t('temp-save-button')}</button>
                        <button className='nextButton' type="button" onClick={handleNext}>{t('next-button')}<FontAwesomeIcon icon={faAngleRight} size='lg' className='iconRight' /></button>
                    </div>
                </form>}
            </div>
        </div>
    );
}

export default InstallationInput;
