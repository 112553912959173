import React, { useState } from 'react';
import axios from 'axios';
import Loading from '../Loading/Loading';
import useLoading from '../Loading/useLoading';
import { useUserInputDialog, UserInputDialog } from '../UserAction/UserInputDialog';
import UserConfirm from '../UserAction/UserConfirm';
import useConfirmationDialog from '../UserAction/useConfirmationDialog';
import { useTranslation } from "react-i18next";
import { useFloatingMessage, FloatingMessage } from '../UserAction/FloatingMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImage, faFilePdf, faFileAlt, faTriangleExclamation, faFile, faList, faListCheck,  faClipboardCheck } from '@fortawesome/free-solid-svg-icons';

const FileRow = (props) => {
    const [deleted, setDeleted] = useState(false);
    const [file, setFile] = useState(props.file);
    const owner = props.owner;
    const loading = useLoading();
    const changeNameInput = useUserInputDialog();
    const confirmDialog = useConfirmationDialog();
    const floatMsg = useFloatingMessage();

    const deleteFile = async () => {
        loading.showLoading(t('loading-delete-file'));
        try {
            const response = await axios.post('/api/deleteFile', { fileId: file.fileId });
            console.log(response);
            if (response.status === 200) {
                setDeleted(true);
                floatMsg.show(t('delete-file-success'), false);
            }
            else {
                await axios.post('/api/addError', { message: 'Error when deleting file: ' + response.json() }, {});
                floatMsg.show(t('delete-file-error'), true);
            }
        } catch (error) {
            await axios.post('/api/addError', { message: 'Error when deleting file: ' + error }, {});
            floatMsg.show(t('delete-file-error'), true);
        }
        loading.hideLoading();

    };

    const changeName = async () => {
        const filename = file.name;
        const splitName = filename.split('.');

        setTimeout(() => {
            changeNameInput.inputRefs.current[0].setAttribute('value', splitName[0]);
            changeNameInput.inputRefs.current[0].select();
        }, 100);

        const fileExtension = splitName.length > 1 ? splitName.pop() : "";
        changeNameInput.show(async (inputValues) => {
            console.log(inputValues);
            const inputValue = inputValues[0];
            loading.showLoading(t('change-name-message'));
            try {
                console.log(inputValue);
                const name = inputValue.split('.')[0] + "." + fileExtension;
                const response = (await axios.post('/api/changeFileName', { fileId: file.fileId, newName: name })).data;
                setFile(response);
            } catch (error) {
                await axios.post('/api/addError', { message: 'Error changing file name: ' + error }, {});
                console.log(error);
            }
            loading.hideLoading();
        }, (t('enter-new-name-form')), ["text"]);
    };

    const promptDeleteFile = (installation) => {
        confirmDialog.show(() => deleteFile(), (t('prompt-delete-file')) + file.name + "?");
    }
    const { t } = useTranslation();

    // This function will be called when a file row is clicked
    const openFileInNewTab = async (fileUrl, fileExtension) => {
        try {
            const response = await fetch(fileUrl);
            if (!response.ok) floatMsg.show(t('file-not-found'), true);
    
            let mimeType;
            switch (fileExtension.toLowerCase()) {
                case 'pdf':
                    mimeType = 'application/pdf';
                    break;
                case 'png':
                    mimeType = 'image/png';
                    break;
                case 'jpg':
                case 'jpeg':
                    mimeType = 'image/jpeg';
                    break;

                default:
                    const blob = await response.blob();
                    const downloadUrl = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = downloadUrl;
                    a.download = fileUrl.split('/').pop(); // Extracts file name from URL
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(downloadUrl);
                    a.remove();
                    return;
            }
    
            // For PDFs and images, open in a new tab
            const blob = new Blob([await response.blob()], { type: mimeType });
            const blobUrl = URL.createObjectURL(blob);
            const newTab = window.open(blobUrl, '_blank');

        } catch (error) {
            await axios.post('/api/addError', { message: 'Error opening file: ' + error }, {});
            console.error('Error opening file', error);
        }
    };
    
    
    const getFileIcon = (extension) => {
        switch (extension) {
            case 'png':
            case 'jpg':
            case 'jpeg':
                return <FontAwesomeIcon icon={faFileImage} color="blue" />;
            case 'pdf':
                return <FontAwesomeIcon icon={faFilePdf} color="red" />;
            default:
                return <FontAwesomeIcon icon={faFileAlt} color="grey" />; // default file icon
        }
    };

    const fileIcon = (fileType) => {
        switch (fileType) {
            case 1:
                return <FontAwesomeIcon icon={faFile}/>;
            case 2:
                return <FontAwesomeIcon icon={faFileImage}/>;
            case 3:
                return <FontAwesomeIcon icon={faList}/>;
            case 4:
                return <FontAwesomeIcon icon={faListCheck}/>;
            case 5:
                return <FontAwesomeIcon icon={faClipboardCheck}/>;
            case 6:
                return <FontAwesomeIcon icon={faTriangleExclamation}/>;
            default:
                return <FontAwesomeIcon icon={faFile}/>;
        }
    }

    return (
        <div className='fileForm'>
            <UserConfirm {...confirmDialog} />
            <UserInputDialog {...changeNameInput} />
            <FloatingMessage {...floatMsg} />
            <Loading {...loading} />

            {!deleted && (
                <div className='fileRow' onClick={() => {
                    const fileExtension = file.name.split('.').pop().toLowerCase();
                    if (["png", "jpg", "jpeg", "pdf"].includes(fileExtension)) {
                        console.log("opening file in new tab");
                        openFileInNewTab(file.url, fileExtension);
                    }
                }}>
                    {/* {["png", "jpg", "jpeg"].includes(file.name.split('.').pop().toLowerCase()) &&
                        <img className='filePreviewImg' src={file.url} alt="File Preview" />} */}
                    <div className='fileIcon'>{fileIcon(file.type)}</div>

                    <div className="fileNameContainer">{file.name}</div>

                    {/* {file.isEditable && <div className="buttonContainer">
                        <button className='deleteButton' onClick={(e) => {
                            e.stopPropagation(); // Prevent the row click event
                            e.preventDefault();
                            promptDeleteFile();
                        }}>{t('delete-button')}</button>
                        <button className='smallActionButton' onClick={(e) => {
                            e.stopPropagation(); // Prevent the row click event
                            e.preventDefault();
                            changeName();
                        }}>{t('change-name-button')}</button>
                    </div>} */}
                </div>
            )}
        </div>
    );
}

export default FileRow;
